import * as Msal from "@azure/msal-browser";
import $router from '@/router'

/**
* Config:
* - app1 is with sso login
* - app2 is for the web API (vHUB core), exposes the API which app1 will need permissions for
* example config:
 "sfx": {
 	"project": {
		"identityProvider": {
			"name": "B2cUserFlow",
			"clientId": "myAppId", // app1
			"tenantId": "myTenantId",
			"redirectUri": "https://URL-to-cc.ch",
			"postLogoutRedirectUri": "https://URL-to-cc.ch/#/logout",
			"tenantName": "mytenantname", // if your domain is mytenantname.onmicrosoft.com, write mytenantname
			"scopes": "https://mytenantname.onmicrosoft.com/myDelegatedAppId/use_vhub", //app2 exposes this api endpoint
			"loginFlowOrPolicyName": "B2C_1_userLoginFlow"
		}
 	}
 }
* **/

const loginRequest = (getters) =>  {
	return {
		scopes: getters.getLoginScopes,
		extraQueryParameters: {ui_locales: 'en', p: getters.getLoginFlowOrPolicyName}
	}
}

const accessTokenRequest = (account, getters) => {
	return {
		scopes: getters.getLoginScopes,
		account: account,
	}
}

const idpClientApi = async (getters, dispatch) => {
    if (!getters.getIdpClient) {
        return await dispatch('loadIdentityProviderClient');
    }
    return getters.getIdpClient;
};

const state = {
	idpName: 'AzB2cUserFlow',
	idpClientApi: null,
	identityProviderConfig: {},
	identityProviderConnectStatus: 'none',
	idpAccount: {},
	loginScopes: [],
	updateScopes: [],
	passwordResetScopes: [],
	loginFlowOrPolicyName: '',
};
const getters = {
	getIdpClient: function() {
		return state.idpClientApi;
	},
	getIdentityProviderName: function() {
		return state.idpName;
	},
	getActiveIdpAccount() {
		return state.idpAccount;
    },
    getLoginScopes() {
		return state.loginScopes;
    },
    getUpdateScopes() {
		return state.updateScopes;
    },
    getPasswordResetScopes() {
		return state.passwordResetScopes;
    },
    getLoginFlowOrPolicyName() {
		return state.loginFlowOrPolicyName;
	},
};
const mutations = {
    setIdentityProviderClientMutation (state, client) {
        state.idpClientApi = client;
    },
    setIdentityProviderConfigMutation(state, data) {
        state.identityProviderConfig = data;
    },
    setIdentityProviderConnectStatus(state, status) {
		state.identityProviderConnectStatus = status;
    },
    setIdentityProviderName(state, name) {
		state.idpName = name;
    },
    setActiveIdpAccount(state, account) {
		state.idpAccount = account;
    },
    setLoginScopes(state, loginScopes) {
		state.loginScopes = loginScopes;
    },
    setUpdateScopes(state, updateScopes) {
		state.updateScopes = updateScopes;
    },
    getPasswordResetScopes(state, passwordResetScopes) {
		state.passwordResetScopes = passwordResetScopes;
    },
    setLoginFlowOrPolicyName(state, name) {
		state.loginFlowOrPolicyName = name;
    },
    
};
const actions = {
	/**
	* Refreshes token when it is about to be expired
	* mandatory for adapters (if not necessary, make an empty method)
	* */
	autoRefreshToken: ({getters, dispatch}) => {
		let timeUntilRefresh = 10;
		if(getters.authToken) {
			const base64Url = getters.authToken.split('.')[1];
			const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));
			const res = JSON.parse(jsonPayload);
			const now = Date.now() / 1000;
			timeUntilRefresh = res.exp - now;
			timeUntilRefresh -= (2 * 60)
			timeUntilRefresh = timeUntilRefresh > 0 ? timeUntilRefresh : 10
		}
		setTimeout(async () => {
			dispatch('autoRefreshToken');
			if(getters.authToken) {
				const accessToken = await dispatch('clientGetSilentAccessToken');
				//console.log(accessToken);
				console.log('new token')
				await dispatch('setAuthToken', accessToken);
				await dispatch('checkLogin', {authToken: accessToken});
			}
		}, timeUntilRefresh * 1000);
	},
	/**
	* Loads the client necessary to perform signin/signout actions
	* mandatory for all adapters
	* */
    loadIdentityProviderClient: async ({commit, dispatch}, counter = 0) => {
        let t = setTimeout(() => {commit('setIdentityProviderConnectStatus', 'waiting')}, 2000);
		return fetch(process.env.VUE_APP_CONFIG)
            .then(r => r.json())
            .then(config => {
				const idpConf = config.identityProvider;
				commit('setLoginScopes', idpConf.loginScopes);
				commit('setLoginFlowOrPolicyName', idpConf.loginFlowOrPolicyName);

                const msalConfig = {
					auth: {
						clientId: idpConf.clientId,
						redirectUri:idpConf.redirectUri, //defaults to application start page
						postLogoutRedirectUri: idpConf.postLogoutRedirectUri,
						authority: `https://${idpConf.tenantName}.b2clogin.com/${idpConf.tenantName}.onmicrosoft.com/${idpConf.loginFlowOrPolicyName}/`,
						knownAuthorities: [`https://${idpConf.tenantName}.b2clogin.com/${idpConf.tenantName}.onmicrosoft.com`]
					},
					cache: {
						cacheLocation: "localStorage", // This configures where your cache will be stored
						storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
					}
				}
				commit('setIdentityProviderConfigMutation', msalConfig);
				return new Msal.PublicClientApplication(msalConfig);
            })
            .then(client => {
				clearTimeout(t);
                commit('setIdentityProviderConnectStatus', 'none')
                commit('setIdentityProviderClientMutation', client);
                console.log('- MSAL identity provider loaded');
                return client;
				// eslint-disable-next-line no-unused-vars
            }).catch(async e => {
				//console.log(e);
				clearTimeout(t);
				commit('setIdentityProviderConnectStatus', 'error');
				if(counter < 60) {
                    setTimeout(async () => {
                        await dispatch('loadIdentityProviderClient', counter + 1);
                    }, 10000);
                }
                throw new Error('Couldn\'t connect to identity provider');
            });
    },
    handleResponse: async ({getters, dispatch, commit}, response) => {
		//console.log(response);
		const client = await idpClientApi(getters, dispatch);
		if (response !== null) {
			const { account, accessToken } = response;
			//console.log(account);
			//console.log('setting account');
			//console.log(accessToken);
			await dispatch('setAuthToken', accessToken);
			await commit('setActiveIdpAccount', account);
		} else {
			// In case multiple accounts exist, you can select
			const currentAccounts = client.getAllAccounts();
			//console.log('current accounts:')
			//console.log(currentAccounts);
			if (currentAccounts.length === 0) {
				//console.log('loginredirect')
				// no accounts signed-in, attempt to sign a user in
				await client.loginRedirect(loginRequest(getters));

				// await client.loginPopup(loginRequest);
			} else if (currentAccounts.length > 1) {
				// Add choose account code here
				console.log('found multiple accounts, what should we do?');
			} else if (currentAccounts.length === 1) {
				console.log('found exactly one account:')
				commit('setActiveIdpAccount', currentAccounts[0]);
				await dispatch('clientGetSilentAccessToken');
				// this.accountId = currentAccounts[0].homeAccountId;
			}
		}
	},
    /**
    * Redirects user to identity provider page
    * not mandatory for adapters, use as needed
    * */
    clientRedirectUser: async ({getters, dispatch}) => {
		let client;
		// eslint-disable-next-line no-undef
		$cookies.set('loggingIn', true)
		return await idpClientApi(getters, dispatch)
            .then(async api => {
				client = api;
				return await client.handleRedirectPromise()
            })
            .then(async response => {
				//console.log(response);
				return await dispatch('handleResponse', response);
			})
		// eslint-disable-next-line no-unused-vars
			.catch(async e => {
				//console.log(e);
				// log the user out and reload the page for the msal-browser
				await dispatch('idpLogout', {withoutAPICall: true});
				location.reload();
			})
    },
    
    /**
    * On page reload or when the token is expired, we need to
    * fetch the accessToken again (we don't store it anywhere)
    * @params getters - default vuex getters
    * @params dispatch - default vuex dispatch
    * */
    clientGetSilentAccessToken: async ({getters, dispatch}, redirectIfFail = true) => {
		return idpClientApi(getters, dispatch)
			.then(async api => {
				//const accounts = api.getAllAccounts()[0];
				//console.log('all accounts')
				//console.log(accounts);
				const account = getters.getActiveIdpAccount;
				return api.acquireTokenSilent(accessTokenRequest(account, getters))
					.then(async function(accessTokenResponse) {
						let accessToken = accessTokenResponse.accessToken;
						//console.log('silent got token')
						//console.log(accessTokenResponse);
						return accessToken;
					}).catch(async function (error) {
						console.log(error);
						//If aqcuiring token fails, redirect to login window
						//console.log(error);
						//console.log(error.name);
						// todo here 2
						if (error.name === "InteractionRequiredAuthError" && redirectIfFail) {
							await dispatch("idpLogout");
							
							// this gets the refresh-token – but with the refreshtoken
							// we have a problem when it expires without internet connection
							// we have to login twice then. So we do not set a refresh token,
							// the user will get logged out when the token expires.
							//api.acquireTokenRedirect(accessTokenRequest(account, getters))
						}
						return null;
					});
            });
    },
    
    /**
    * Standard secondary login method for idp,
    * after the user first entered the credentials,
    * needs to be implemented by all identity provider stores
    * */
    idpCheckLogin: async ({dispatch, commit}) => {
		// case 0: error or logout cases
		if($router.currentRoute.name === 'logout' || $router.currentRoute.path.startsWith('/error') || $router.currentRoute.path.startsWith('/#error')) {
			commit('setAuthToken', {authToken: null});
			commit('setLoading', false);
			await $router.push('/');
			commit('setActiveIdpAccount', {});
			return;
		}
		
		const accessToken = await dispatch('clientGetSilentAccessToken', false);
		// case 1: user is already logged in
		if(accessToken) {
			commit('setLoading', true);
			await dispatch('setAuthToken', accessToken);
			await dispatch('checkLogin', {authToken: accessToken});
			// eslint-disable-next-line no-undef
		}
		// eslint-disable-next-line no-undef
		else if ($cookies.get('loggingIn')) {
			await dispatch('clientRedirectUser');
			await dispatch('clientGetSilentAccessToken', false);
			await dispatch('checkLogin', {authToken: accessToken});
		}
		else {
			commit('setLoading', false);
		}
    },
    
    /**
    * Standard logout method for idp
    * mandatory for all adapters
    * */
    idpLogout: async ({getters, dispatch}, args) => {
		const withoutAPICall = args && args.withoutAPICall ? args.withoutAPICall : false;
        // eslint-disable-next-line no-undef
        $cookies.set('loggingIn', false)
        if(!withoutAPICall) {
			return idpClientApi(getters, dispatch)
			.then(async api => {
				await api.logout();
            }).then(() => {
				dispatch('deleteAllCookies');
				window.sessionStorage.clear();
            });
        }
    },
    
    /**
    * Gets all accounts stored in the msal cache
    * */
    clientGetAllUserAccounts: async ({getters, dispatch}) => {
		return idpClientApi(getters, dispatch)
			.then(api => {
				return api.getAllAccounts();
            }).then(accounts => {
				//console.log(accounts);
				return accounts;
			// eslint-disable-next-line no-unused-vars
            }).catch(e => {
				//console.log(e);
            });
    },
};
export default {
    state,getters,mutations,actions
}
